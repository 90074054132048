import { lazy, Suspense, useEffect, useState } from 'react';

import AppAlert from '@m/components/AppAlert';
import { useHeapIdentifyUser } from '@m/hooks/useHeapIdentifyUser';
import { KeycloakService } from '@m/keycloak-service/KeycloakService';
import { useUnit } from 'effector-react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { IamUserService } from 'apps/portal/services/IamUserService';
import { authStore, getUser } from 'apps/portal/state/auth';
import { userAnalytics } from 'utils/userAnalytics';

import { AlertBox, AntdOverrides, AppBox, ContentBox, GlobalStyles } from './Portal.styled';

import { User } from 'types/api';

const NavContainer = lazy(() => import('apps/portal/containers/NavContainer'));
const WelcomeContainer = lazy(() => import('apps/portal/containers/WelcomeContainer'));
const LoginContainer = lazy(() => import('apps/portal/containers/LoginContainer'));
const DashboardContainer = lazy(() => import('apps/portal/containers/DashboardContainer'));
const SubscriptionsContainer = lazy(() => import('apps/portal/containers/SubscriptionsContainer'));
const PassPlansContainer = lazy(() => import('apps/portal/containers/PassPlansContainer'));
const SubDetailContainer = lazy(() => import('apps/portal/containers/SubDetailContainer'));
const PassPlansDetailContainer = lazy(
  () => import('apps/portal/containers/PassPlanDetailContainer'),
);
const InvoiceContainer = lazy(() => import('apps/portal/containers/InvoiceContainer'));
const PdfInvoiceContainerNEW = lazy(() => import('apps/portal/containers/PdfInvoiceContainerNEW'));
const OneTimePaymentPage = lazy(() => import('apps/portal/containers/OneTimePaymentPage'));
const PaymentHistoryContainer = lazy(
  () => import('apps/portal/containers/PaymentHistoryContainer'),
);

export default function Portal() {
  const {
    authenticated,
    user,
    status: { getUserPending, loginPending },
  } = useUnit(authStore);
  const [isKeycloakUser, setIsKeycloakUser] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    let proxyUser = user;
    if (KeycloakService.isLoggedIn()) {
      proxyUser = KeycloakService.getUserObject() as User;
    }
    if (authenticated && proxyUser) {
      userAnalytics.setUser(proxyUser);
    }
  }, [authenticated, user]);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (authenticated || KeycloakService.isLoggedIn()) {
      if (
        pathname !== '/dashboard' &&
        pathname !== '/billing' &&
        pathname !== '/login' &&
        pathname !== '/payment-history' &&
        !pathname.match(/^\/pass-plans/) &&
        !pathname.match(/^\/subscriptions/) &&
        !pathname.match(/^\/billing/) &&
        !pathname.match(/^\/enterprise/)
      ) {
        history.replace('/dashboard');
      }
    } else if (
      !(getUserPending || loginPending) &&
      authenticated === false &&
      pathname !== '/welcome' &&
      pathname !== '/login'
    ) {
      history.replace('/welcome');
    }
  }, [authenticated, getUserPending, history, loginPending, pathname]);

  useHeapIdentifyUser({
    authenticated,
    user,
  });

  /* hide for one time payment pages
     e.g. /billing/123/pay or /billing/pay */
  const hideNavBar = !!(
    pathname.match(/^\/billing\/\d+\/pay/) || pathname.match(/^\/billing\/pay/)
  );

  // check if user is loggedin from keycloak
  useEffect(() => {
    IamUserService.getLoggedInUser()
      .then((response) => {
        setIsKeycloakUser(!!response);
      })
      .catch(() => {
        setIsKeycloakUser(false);
      });
  }, []);

  return (
    <>
      <GlobalStyles />
      <AlertBox>
        <AppAlert
          errorBgColor="#FFF4F5"
          errorFgColor="#FF7262"
          position="relative"
          left={0}
          flexShrink={0}
          paddingX="16px"
          minWidth="200px"
          maxWidth="503px"
          boxShadow="0px 5px 15px rgba(0, 0, 0, 0.05)"
          padding="24px"
          borderRadius="16px"
          border="1px solid transparent"
          css={`
            @media (min-width: 700px) {
              width: auto !important;
              left: 0 !important;
            }
          `}
        />
      </AlertBox>
      <AppBox>
        <Suspense fallback={null}>
          {!hideNavBar && (
            <NavContainer authenticated={authenticated} isKeycloakUser={isKeycloakUser} />
          )}
        </Suspense>
        <ContentBox authenticated={authenticated} removePadding={hideNavBar}>
          <Switch>
            <Route exact path="/dashboard">
              <Suspense fallback={null}>{authenticated && <DashboardContainer />}</Suspense>
            </Route>
            <Route exact path="/welcome">
              <Suspense fallback={null}>
                <WelcomeContainer />
              </Suspense>
            </Route>
            <Route exact path="/login">
              <Suspense fallback={null}>
                <LoginContainer />
              </Suspense>
            </Route>
            <Route exact path="/billing">
              <Suspense fallback={null}>{authenticated && <InvoiceContainer />}</Suspense>
            </Route>
            <Route exact path={['/billing/:invoiceId/pay', '/billing/pay']}>
              <Suspense fallback={null}>{authenticated && <OneTimePaymentPage />}</Suspense>
            </Route>
            <Route exact path="/enterprise/:enterpriseId/invoice/:invoiceId">
              <Suspense fallback={null}>{authenticated && <PdfInvoiceContainerNEW />}</Suspense>
            </Route>

            <Route exact path="/subscriptions">
              <Suspense fallback={null}>{authenticated && <SubscriptionsContainer />}</Suspense>
            </Route>
            <Route exact path="/subscriptions/:subscriptionId/details">
              <Suspense fallback={null}>{authenticated && <SubDetailContainer />}</Suspense>
            </Route>
            <Route exact path="/payment-history">
              <Suspense fallback={null}>{authenticated && <PaymentHistoryContainer />}</Suspense>
            </Route>
            <Route exact path="/pass-plans">
              <Suspense fallback={null}>{authenticated && <PassPlansContainer />}</Suspense>
            </Route>
            <Route exact path="/pass-plans/:parkingPassPlanId/details">
              <Suspense fallback={null}>{authenticated && <PassPlansDetailContainer />}</Suspense>
            </Route>
          </Switch>
        </ContentBox>
      </AppBox>
      {/* AntdOverrides is at the bottom so it has the most specificity */}
      <AntdOverrides />
    </>
  );
}
